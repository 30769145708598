import * as Statics from '@shared/core/statics';
import { CollectionTypeHelper } from './collection-type-helper.utils';

interface LocationDetailsForOrderTypeParam {
    location: OLO.DTO.OnlineOrderingLocationBusinessModel;
    orderTypeId: Nullable<number>;
    backupOrderTypeId?: Nullable<number>;
}

type LocationOrderTypeDetails = Omit<Unpacked<OLO.DTO.OnlineOrderingLocationBusinessModel['OrderTypes']>, 'TypeDescription' | 'IsEnabledForFutureOrdering'> &
Pick<OLO.DTO.OnlineOrderingLocationBusinessModel, 'LocationNo'>;

export class LocationFutureOrdering {
    /**
     * Extract location future ordering details based on cart's or current's order type details
     * @param {LocationDetailsForOrderTypeParam} data required details - location, cartOrderTypeId and backup currentCollectionTypeId
     */
    public static getLocationFutureOrderingDetails(data: LocationDetailsForOrderTypeParam): LocationOrderTypeDetails {
        const { location, orderTypeId, backupOrderTypeId } = data;
        /**
         * * if both min/max === null, future order is disabled
         * * if both min/max === 0, can order for today only
         * * if both min/max have values, can order only for those days ahead
         */
        const model: LocationOrderTypeDetails = {
            Id: orderTypeId || backupOrderTypeId,
            FutureOrderingMinDaysAhead: location?.FutureOrderingMinDaysAhead ?? null,
            FutureOrderingMaxDaysAhead: location?.FutureOrderingMaxDaysAhead ?? null,
            LocationNo: location?.LocationNo ?? null,
        };

        const isCollectionTypeIdSet = model.Id < 0;
        if(isCollectionTypeIdSet) {
            let foundCollectionTypeOrderIds = new CollectionTypeHelper(new Statics.ConfigStatic().current.collectionTypes).getCollectionTypeByOrderTypeId(model.Id).orderTypeIds;

            const relatedOrderType: Nullable<OLO.DTO.TerminalLocationOrderTypeModel> = foundCollectionTypeOrderIds?.reduce((mappedOrderTypeId, rootOrderTypeId) => {
                /* Just map the first found - others must have same future ordering settings */
                if(mappedOrderTypeId) return mappedOrderTypeId;

                return location?.OrderTypes?.find(obj => obj.Id === rootOrderTypeId && obj.IsEnabledForFutureOrdering === true) ?? null;
            }, null as Nullable<OLO.DTO.TerminalLocationOrderTypeModel>) || null;

            if(relatedOrderType) {
                //  Don't set model.id - this might be misleading. 0 will be treated as a special case
                //  There might be multiple orderTypeIds that would match, but we want just one that mateches.
                //  Others will have same future ordering settings. If not, then there is a setup issue
                model.Id = 0;
                model.FutureOrderingMinDaysAhead = relatedOrderType.FutureOrderingMinDaysAhead;
                model.FutureOrderingMaxDaysAhead = relatedOrderType.FutureOrderingMaxDaysAhead;
            }
        }

        const foundOrderTypeDetails = location?.OrderTypes?.find((obj) => obj.Id === model.Id && obj.IsEnabledForFutureOrdering === true);
        if (!location || !model.Id || !foundOrderTypeDetails) {
            model.Id = null;

            return model;
        }

        model.Id = foundOrderTypeDetails.Id;
        model.FutureOrderingMinDaysAhead = foundOrderTypeDetails.FutureOrderingMinDaysAhead;
        model.FutureOrderingMaxDaysAhead = foundOrderTypeDetails.FutureOrderingMaxDaysAhead;

        return model;
    }

    public static getMaxDaysAheadValue(futureOrderingMaxDaysAhead: number, orderTypes: OLO.DTO.TerminalLocationOrderTypeModel[]): number {
        if (!orderTypes.length || futureOrderingMaxDaysAhead === null) return null;

        return orderTypes.reduce((maxDaysAheadValue, orderType) => {
            if (orderType.IsEnabledForFutureOrdering && orderType.FutureOrderingMaxDaysAhead > maxDaysAheadValue) {
                return orderType.FutureOrderingMaxDaysAhead;
            }

            return maxDaysAheadValue;
        }, futureOrderingMaxDaysAhead);
    };
}
