import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { getOrderTypesForAllLocations } from '@shared/state/orderTypes/selectors';
import { getOnlineOrderState } from '@shared/state/onlineOrder/selectors';

export const isOrderTypeValid = createSelector(getOnlineOrderState, getOrderTypesForAllLocations, (onlineOrder, orderTypes) => {
    const ot = onlineOrder.orderType;

    if (ot === null && orderTypes && onlineOrder.data?.PickupLocation) return orderTypes.map((type) => type.locationNo).includes(onlineOrder?.data?.PickupLocation);

    if (!ot) return false;

    const pageConfig = Utils.DynamicPages.customPageSetup('checkoutPage', ot?.Id);
    const isDisclaimerValid: boolean = Utils.OnlineOrders.isOrderTypeDisclaimerValid(ot, pageConfig?.orderTypesDisclaimers);
    const disclaimersLength = ot.Disclaimers.length;

    const hasAnyDisclaimers: boolean = (pageConfig.orderTypesDisclaimers === true && disclaimersLength >= 1);
    if (disclaimersLength === 0 || hasAnyDisclaimers) {
        return hasAnyDisclaimers ? isDisclaimerValid : true;
    }

    const hasRequired = ot.Details.some((obj) => obj.IsRequired === true);
    if (!hasRequired) return true;

    return ot.Details.reduce((flag, field) => {
        if (flag === false) return false;
        if (field.IsRequired && !!field._Value === false) {
            return false;
        }

        return true;
    }, isDisclaimerValid);
});
